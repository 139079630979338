import { IS_DEV, VUE_APP_TENANT_ID } from "@/constants/enviroments"

const storeDevelopment = {
  id: VUE_APP_TENANT_ID,
  name: "lorem",
  slug: "lorem"
}

export const rolesDevelopment = {
  "cxaas-customer": {
    "roles": [
      "admin",
      "enduser",
      "keyaccount"
    ]
  }
}

export const DEFAULT_AUTH = {
  authToken: window.parent.IFC_VARIABLES.auth.token,
  sellerId: '',
  store: IS_DEV ? storeDevelopment : {
    id: undefined,
    name: '',
    slug: '',
  },
  userRoles: {}
}
