import { Patterns } from "@/utils"

export default (Vue) => {
  Vue.filter('phone', (value) => {
    if (!value) return ''
    return `+${value?.replace(/\D/g, '')?.replace(
      Patterns.phone.pattern,
      Patterns.phone.replace
    )}`
  })
}
