/* services */
import AccountService from '@/services/account'

/* vuex */
import store from './index'

export default {
  namespaced: true,
  state: {
    data: [],
    expire: 0
  },
  getters: {
    getSalesChannels (state) {
      // if state.data is empty, will be loaded from local storage 'customer-sales_channel'
      if (state.data.length === 0) {
        const salesChannel = JSON.parse(localStorage.getItem('customer-sales_channel'))

        // if in localStorage 'customer-sales_channel' have any data, will be placed in state.data and state.expire
        if (salesChannel && salesChannel.data && salesChannel.data.length > 0) {
          // set data from localStorage
          state.data = salesChannel.data
          // set expire from localStorage
          state.expire = salesChannel.expire
        }
      }

      // get now timestamp
      const now = new Date().getTime()

      // verify if is expired, returning a boolean
      const expired = !(Number(state.expire) > now)

      if (expired) {
        store.dispatch('SalesChannel/updateSalesChannel')
      }

      return {
        data: state.data,
        expire: state.expire,
        expired: !(Number(state.expire) > now)
      }
    }
  },
  actions: {
    // update the values in sales channel
    async updateSalesChannel (context) {
      try {
        // get values from api service
        const response = await AccountService.salesChannels()
        const salesChannels = response.data.map((s) => {
          return { label: s.name, value: s.id }
        })

        // pass data from api to mutation 'salesChannelMutation'
        await context.commit('UPDATE_SALES_CHANNEL', salesChannels)
      } catch (e) {
        console.error('account/sales-channel error', e)
      }
    }
  },
  mutations: {
    async UPDATE_SALES_CHANNEL (state, salesChannel) {
      const fiveMinutes = new Date().getTime() + (1000 * 60 * 5)
      // salesChannel received in parameter is seted to state
      state.data = salesChannel
      // defining the new expire timestamp
      state.expire = fiveMinutes
      localStorage.setItem('customer-sales_channel', JSON.stringify(state))
    }
  }
}
