const getUrl = (path) => {
  return window.parent.IFC_VARIABLES.baseURL + path
}

const getService = () => {
  return {
    VUE_APP_API_BASE_URL_ADMIN: getUrl(
      process.env.VUE_APP_API_BASE_URL_ADMIN_PATH
    ),
    VUE_APP_API_SALES: getUrl(process.env.VUE_APP_API_SALES_PATH),
    VUE_APP_API_ACCOUNT: getUrl(process.env.VUE_APP_API_ACCOUNT_PATH)
  }
}

export default getService
