export function copyText(str) {
  try {
    const el = document.createElement('textarea');
    el.addEventListener('focusin', e => e.stopPropagation());
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  } catch (error) {
    console.error(error)
  }
}
