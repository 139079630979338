export const PATH_SUBSCRIPTIONS = '/subscriptions'
export const SUBSCRIPTIONS_ROUTER_NAME = 'Subscriptions'

export default {
  path: PATH_SUBSCRIPTIONS,
  name: SUBSCRIPTIONS_ROUTER_NAME,
  component: () => import('@/views/subscription/list/Subscriptions.vue'),
  meta: {
    title: 'Listagem de Inscritos',
    roles: {
      isAuthenticated: true,
      isSuperAdmin: false,
      isAdmin: false
    }
  }
}
