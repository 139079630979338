export const PATH_CLIENT_VIEW = '/:client/view'
export const CLIENT_VIEW_ROUTER_NAME = 'Client View'

export default {
  path: PATH_CLIENT_VIEW,
  name: CLIENT_VIEW_ROUTER_NAME,
  component: () => import('@/views/client/details/Details.vue'),
  meta: {
    title: 'Visualização do Cliente',
    roles: {
      isAuthenticated: true,
      isSuperAdmin: false,
      isAdmin: false
    }
  }
}
