import App from '@/App.vue'
import { auth } from '@/helpers'
import plugins from '@/plugins'
import router from '@/router'
import store from '@/store'
import singleSpaVue from 'single-spa-vue'
import Vue from 'vue'

Vue.config.productionTip = false

const postMessageTemp = window.parent.postMessage
window.parent.postMessage = function (message, targetOrigin, transfer) {
  postMessageTemp(JSON.parse(JSON.stringify(message)), targetOrigin, transfer)
}

window.addEventListener('message', function (e) {
  const { data } = e
  window.app.$router.push(data.pathname)
})

Vue.mixin({
  computed: {
    $auth: () => auth
  }
})

const requireMixin = require.context('./mixins', false, /\w+\.(js)$/)

requireMixin.keys().forEach((fileName) => {
  const mixinFile = requireMixin(fileName)
  if (typeof mixinFile.default === 'function') mixinFile.default(Vue)
})

const requireFilter = require.context('./filters', false, /\w+\.(js)$/)

requireFilter.keys().forEach((fileName) => {
  const filterFile = requireFilter(fileName)
  if (typeof filterFile.default === 'function') filterFile.default(Vue)
})

const options = { router, store, ...plugins }

let vueLifecycles = {}

const loadAppAsMFE = async () => {
  return singleSpaVue({
    Vue,
    appOptions: {
      mixins: [options],
      render(h) {
        return h(App, {
          props: {
            basepath: this.basepath,
            location: this.location
          }
        })
      },
      el: '#mfe_div_connector'
    },
    handleInstance(app, props) {
      auth.listen(app.tokenIdSubject, app.$root.$emit, app.$root)
    }
  })
}

vueLifecycles = loadAppAsMFE()

export const bootstrap = (props) => {
  return vueLifecycles.then((cycles) => {
    cycles.bootstrap(props)
  })
}

export const mount = (props) => {
  return vueLifecycles.then((cycles) => {
    cycles.mount(props)
  })
}

export const unmount = (props) => {
  return vueLifecycles.then((cycles) => {
    cycles.unmount(props)
  })
}

export const withoutSingleSpa = ({ elementDOM, basePath, location }) => {
  const options = {
    store,
    ...plugins
  }

  window.app = new Vue({
    mixins: [
      {
        router: router,
        mounted: function () {
          if (location) {
            router.push(location)
          }
        },
        ...options,
        computed: {
          $auth: () => auth
        }
      }
    ],
    render(h) {
      return h(App, {})
    }
  })

  auth.listen(
    Vue.observable({ token: window.parent.IFC_VARIABLES.auth.token }),
    window.app.$root.$emit,
    window.app.$root
  )

  return window.app.$mount(elementDOM)
}
