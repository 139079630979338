import LoggedLayout from '@/layout/Logged'

import UNAUTHORIZED from '@/router/routes/unauthorized'

import CLIENTS from '@/router/routes/clients'
import VIEW_CLIENT from '@/router/routes/view-client'
import VIEW_SUBSCRIPTION from '@/router/routes/view-subscription'
import SUBSCRIPTIONS from '@/router/routes/subscriptions'
import CLUSTERS from '@/router/routes/clusters'

const CLIENT = [ CLIENTS, VIEW_CLIENT, CLUSTERS ]
const SUBSCRIPTION = [ VIEW_SUBSCRIPTION, SUBSCRIPTIONS ]

export const BASE_PATH = '/'
export default [
  {
    path: BASE_PATH,
    component: LoggedLayout,
    children: [ ...CLIENT, ...SUBSCRIPTION, UNAUTHORIZED ]
  }
]
