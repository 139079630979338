import { IS_DEV } from "@/constants/enviroments"
import { jwtDecode } from "@/utils"
import { DEFAULT_AUTH, rolesDevelopment } from "./default-auth"

function convertListRoles (rolesAccount) {
  const authorized = !!rolesAccount?.length
  const roles = rolesAccount?.reduce((a, v) => ({ ...a, [v]: true}), {}) ?? {}

  return { ...roles, authorized }
}

function Auth () {
  this.listen = (tokenObservable, eventBus, ctx) => {
    if (IS_DEV) {
      const tokenDecode = jwtDecode(DEFAULT_AUTH.authToken)
      const roles = convertListRoles(rolesDevelopment?.['cxaas-customer']?.roles)

      this.token = DEFAULT_AUTH.authToken
      this.seller.id = DEFAULT_AUTH.sellerId
      this.account = DEFAULT_AUTH.store
      this.tenantId = DEFAULT_AUTH?.store?.id
      this.sessionId = tokenDecode?.session_state ?? ''
      this.userRoles = roles

      eventBus.apply(ctx, [this.event + 'update', this ])
      return
    }

    if (DEFAULT_AUTH.authToken !== '') {
      const tokenDecode = jwtDecode(DEFAULT_AUTH.authToken)
      const rolesAccount = tokenDecode?.resource_access?.['cxaas-customer']?.roles

      const roles = convertListRoles(rolesAccount)

      this.token = DEFAULT_AUTH.authToken
      // this.seller.id = auth?.sellerId
      this.account = {
        id: localStorage.getItem('ifc-tenant-id') ?? window.parent.IFC_VARIABLES.headers['x-tenant-id'],
        name: "lorem",
        slug: "lorem"
      }
      this.tenantId = localStorage.getItem('ifc-tenant-id') ?? window.parent.IFC_VARIABLES.headers['x-tenant-id']
      this.channelId = localStorage.getItem('ifc-channel-id') ?? window.parent.IFC_VARIABLES.headers['x-channel-id']
      this.sessionId = tokenDecode?.session_state ?? ''
      this.userRoles = roles
    }
  }

  this.unsubscribe = (tokenObservable) => {
    return tokenObservable?.unsubscribe();
  }
}
function AppShellAuthObject (data) {
  this.id = data.id
}

Auth.prototype.event = 'appshell:auth:'
Auth.prototype.token = null
Auth.prototype.tenantId = null
Auth.prototype.sessionId = null
Auth.prototype.seller = new AppShellAuthObject({})
Auth.prototype.account = new AppShellAuthObject({})
Auth.prototype.userRoles = {}

export default new Auth()
