import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from '@/router/guard/beforeEach'
import routes from '@/router/routes'
import { CLIENT_ROUTER_NAME } from './routes/clients'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  routes: [
    ...routes,
    {
      path: '*',
      redirect: {
        name: CLIENT_ROUTER_NAME
      }
    }
  ]
})

beforeEach(router)

export default router
