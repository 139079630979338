import Vue from 'vue'
import Vuex from 'vuex'
import SalesChannel from './salesChannel'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    SalesChannel
  }
})
