import { accountApi } from '@/lib/axios'
export default class AccountService {
  static salesChannels () {
    const url = `/sales-channel?page=1&perPage=${Number.MAX_SAFE_INTEGER}&orderBy=name&orderDirection=asc%20or%20desc`

    return accountApi.get(url).then(res => res.data)
  }
}

const URL = "/accounts"

export const getSellers = async () => {
  const { data } = await accountApi.get(URL, {
    params: {
      dateFilterType: '',
      startDate: '',
      endDate: '',
      search: '',
      accountType: 'seller',
      orderDirection: 'asc',
      page: 1,
      perPage: Number.MAX_SAFE_INTEGER,
    }
  })

  return data
}
