export const PATH_SUBSCRIPTION_VIEW = '/subscriptions/:subscription/view'
export const SUBSCRIPTION_VIEW_ROUTER_NAME = 'SubscriptionView'

export default {
  path: PATH_SUBSCRIPTION_VIEW,
  name: SUBSCRIPTION_VIEW_ROUTER_NAME,
  component: () => import('@/views/subscription/details/Details.vue'),
  meta: {
    title: 'Detalhes da Inscrição',
    roles: {
      isAuthenticated: true,
      isSuperAdmin: false,
      isAdmin: false
    }
  }
}
