import getService from '@/utils/get-service'
import axios from 'axios'
import Vue from 'vue'

const services = getService()
// const xTenantId = process.env.NODE_ENV === 'local' ? { 'X-Tenant-Id': process.env.VUE_APP_TENANT_ID } : {}
const xTenantId = {
  'X-Tenant-Id':
    localStorage.getItem('ifc-tenant-id') ??
    window.parent.IFC_VARIABLES.headers['x-tenant-id']
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  ...xTenantId
}

if (process.env.NODE_ENV === 'local') {
  defaultHeaders['Access-Control-Allow-Origin'] = '*'
}

const customerApi = axios.create({
  baseURL: services.VUE_APP_API_BASE_URL_ADMIN,
  timeout: 5000,
  headers: defaultHeaders
})

const accountApi = axios.create({
  baseURL: services.VUE_APP_API_ACCOUNT,
  timeout: 5000,
  headers: defaultHeaders
})

const salesApi = axios.create({
  baseURL: services.VUE_APP_API_SALES,
  timeout: 5000,
  headers: defaultHeaders
})

const setInterceptors = (instance) => {
  instance.interceptors.request.use(
    function (config) {
      const instanceVue = new Vue()

      return {
        ...config,
        headers: {
          ...config.headers,
          'X-Tenant-Id': instanceVue?.$auth?.tenantId,
          'X-Channel-Id': instanceVue?.$auth?.channelId,
          'x-session-id': instanceVue?.$auth?.sessionId
        }
      }
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}

setInterceptors(customerApi)
setInterceptors(accountApi)
setInterceptors(salesApi)

export { accountApi, customerApi, salesApi }

export default customerApi
