import dayjs from '@/lib/dayjs'

export const dateFormat = (date, format) => {
  try {
    const formattedDate = dayjs(date).format(format)
    return formattedDate
  } catch (e) {
    return date
  }
}
