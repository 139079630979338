// import { UNAUTHORIZED_ROUTER_NAME } from "@/router/routes/unauthorized"
// import Vue from "vue"
// import { CLIENT_ROUTER_NAME } from "../routes/clients"

export default (router) => {
  router.beforeEach(async (to, _, next) => {
    // const application = window.app
    document.title = to.meta.title || 'Clientes'

    // if (to?.meta?.roles?.isAuthenticated && !application?.$auth?.userRoles?.authorized) {
    //   return next({
    //     name: UNAUTHORIZED_ROUTER_NAME
    //   })
    // }

    // if (to?.meta?.roles?.isSuperAdmin && !application?.$auth?.userRoles?.admin) {
    //   return next({
    //     name: to?.meta?.redirectTo || CLIENT_ROUTER_NAME
    //   })
    // }

    return next()
  })
}
