export const PATH_CLIENT = '/'
export const CLIENT_ROUTER_NAME = 'Clients'

export default {
  path: PATH_CLIENT,
  name: CLIENT_ROUTER_NAME,
  component: () => import('@/views/client/list/Clients.vue'),
  meta: {
    title: 'Listagem de Clientes',
    roles: {
      isAuthenticated: true,
      isSuperAdmin: false,
      isAdmin: false
    }
  }
}
