<template>
  <ifc-main :isDev="isDev" data-app>
    <router-view :key="$route.fullPath" />
  </ifc-main>
</template>

<script>
import { IS_DEV } from './constants/enviroments';
import router from './router';

export default {
  name: 'App',
  props: ['location'],
  computed: {
    isDev() {
      return IS_DEV
    }
  },
  watch: {
    $route: function ({ path, fullPath, query }) {
      window.parent.postMessage({ path, fullPath, query }, '*')
    }
  },
  methods: {
    handleRouteChange(event) {
      router.push(event.detail.newRoute)
    }
  },
  created() {
    this.newRoute = this.location.includes('old-app')
      ? this.location.replace(
          `/old-app/${window.parent.IFC_VARIABLES.headers['x-tenant-id']}/customer`,
          ''
        )
      : this.$route.fullPath
  },
  mounted() {
    this.location.includes('old-app') && router.push(this.newRoute)
    window.addEventListener('changeRoute', this.handleRouteChange)

    // Make sure to remove the event listener when the component is destroyed
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('changeRoute', this.handleRouteChange)
    })
  }
}
</script>
<style lang="scss">
.v-application {
  background: #fafafc!important;
}

.ifc-dialog {
  line-break: normal !important;
  height: max-content !important;
}

.ifc-main {
  width: 100% !important;
}
</style>
